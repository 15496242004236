<template>
  <header class="pt-28">
    <slot />
  </header>
</template>

<script>
export default {
  name: "Hero",
  props: {
    showCutout: {
      default: true,
      type: Boolean
    }
  },
  methods: {
    observeHero() {
      const config = {
        root: null,
        rootMargin: "-150px",
        threshold: 0
      };
      const observer = new IntersectionObserver((entries, self) => {
        const hero = entries[0];
        if (hero.isIntersecting) {
          this.$emit("update:visible", true);
        } else {
          this.$emit("update:visible", false);
        }
      }, config);
      observer.observe(this.$el);
    }
  },
  mounted() {
    this.observeHero();
  }
};
</script>

<style lang="scss" scoped>
.hero-cutout {
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    height: 25%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
  }
}

.cutout-up {
  &::before {
    background-image: url("~@/assets/img/cutout-up.svg");
  }
}

.cutout-down {
  &::before {
    background-image: url("~@/assets/img/cutout-down.svg");
  }
}
</style>
