<template>
  <div class="w-100">
    <div
      ref="wrapper"
      class="position-relative mx-auto"
      style="width: 539px; height: 324px; transform-origin: left center;"
      :style="wrapperStyle"
    >
      <div
        v-for="(point, idx) in points"
        :key="idx"
        class="position-absolute wrapper"
        :style="
          point.active
            ? `left: calc(${point.coords[0]}% - 3px); top: calc(${point.coords[1]}% - 3px);`
            : `left: ${point.coords[0]}%; top: ${point.coords[1]}%;`
        "
      >
        <div
          class="rounded-circle point position-absolute"
          :class="
            point.active
              ? 'active cursor-pointer transition-fast'
              : 'bg-gray-300'
          "
        ></div>
        <div
          v-if="point.active"
          class="active-glow transition-fast"
          :style="`animation-delay: ${randomDelay()}s`"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReachMap",
  data() {
    return {
      wrapperStyle: {
        transform: "scale(1)"
      },
      activePoint: {
        animationDelay: "0s"
      },
      points: [
        { coords: [3.57, 0] },
        { coords: [7.14, 0], active: true },
        { coords: [10.71, 0] },
        { coords: [14.28, 0] },
        { coords: [17.85, 0] },
        { coords: [92.82, 0] },
        { coords: [3.57, 5.88], active: true },
        { coords: [7.14, 5.88] },
        { coords: [10.71, 5.88] },
        { coords: [14.28, 5.88] },
        { coords: [17.85, 5.88] },
        { coords: [21.42, 5.88] },
        { coords: [24.99, 5.88] },
        { coords: [28.56, 5.88] },
        { coords: [32.13, 5.88] },
        { coords: [35.7, 5.88] },
        { coords: [39.27, 5.88] },
        { coords: [42.84, 5.88] },
        { coords: [46.41, 5.88] },
        { coords: [49.98, 5.88] },
        { coords: [92.82, 5.88], active: true },
        { coords: [96.39, 5.88] },
        { coords: [3.57, 11.76], active: true },
        { coords: [7.14, 11.76] },
        { coords: [10.71, 11.76] },
        { coords: [14.28, 11.76] },
        { coords: [17.85, 11.76] },
        { coords: [21.42, 11.76], active: true },
        { coords: [24.99, 11.76] },
        { coords: [28.56, 11.76] },
        { coords: [32.13, 11.76] },
        { coords: [35.7, 11.76] },
        { coords: [39.27, 11.76], active: true },
        { coords: [42.84, 11.76] },
        { coords: [46.41, 11.76] },
        { coords: [49.98, 11.76] },
        { coords: [53.55, 11.76] },
        { coords: [89.25, 11.76], active: true },
        { coords: [92.82, 11.76] },
        { coords: [96.39, 11.76] },
        { coords: [0, 17.64] },
        { coords: [3.57, 17.64] },
        { coords: [7.14, 17.64] },
        { coords: [10.71, 17.64] },
        { coords: [14.28, 17.64], active: true },
        { coords: [17.85, 17.64] },
        { coords: [21.42, 17.64] },
        { coords: [24.99, 17.64] },
        { coords: [28.56, 17.64] },
        { coords: [32.13, 17.64] },
        { coords: [35.7, 17.64] },
        { coords: [39.27, 17.64] },
        { coords: [42.84, 17.64] },
        { coords: [46.41, 17.64], active: true },
        { coords: [49.98, 17.64] },
        { coords: [53.55, 17.64], active: true },
        { coords: [57.12, 17.64] },
        { coords: [60.69, 17.64] },
        { coords: [85.68, 17.64] },
        { coords: [89.25, 17.64] },
        { coords: [92.82, 17.64], active: true },
        { coords: [0, 23.52] },
        { coords: [3.57, 23.52] },
        { coords: [7.14, 23.52] },
        { coords: [10.71, 23.52], active: true },
        { coords: [14.28, 23.52] },
        { coords: [17.85, 23.52] },
        { coords: [21.42, 23.52] },
        { coords: [24.99, 23.52] },
        { coords: [28.56, 23.52], active: true },
        { coords: [32.13, 23.52] },
        { coords: [35.7, 23.52], active: true },
        { coords: [39.27, 23.52] },
        { coords: [42.84, 23.52], active: true },
        { coords: [46.41, 23.52] },
        { coords: [49.98, 23.52] },
        { coords: [53.55, 23.52] },
        { coords: [57.12, 23.52], active: true },
        { coords: [60.69, 23.52] },
        { coords: [67.83, 23.52] },
        { coords: [71.4, 23.52] },
        { coords: [82.11, 23.52] },
        { coords: [85.68, 23.52] },
        { coords: [89.25, 23.52], active: true },
        { coords: [92.82, 23.52], active: true },
        { coords: [0, 29.4] },
        { coords: [3.57, 29.4] },
        { coords: [7.14, 29.4] },
        { coords: [10.71, 29.4] },
        { coords: [14.28, 29.4] },
        { coords: [17.85, 29.4] },
        { coords: [21.42, 29.4] },
        { coords: [24.99, 29.4] },
        { coords: [28.56, 29.4] },
        { coords: [32.13, 29.4] },
        { coords: [35.7, 29.4] },
        { coords: [39.27, 29.4] },
        { coords: [42.84, 29.4] },
        { coords: [46.41, 29.4] },
        { coords: [49.98, 29.4] },
        { coords: [53.55, 29.4] },
        { coords: [57.12, 29.4] },
        { coords: [60.69, 29.4] },
        { coords: [64.26, 29.4], active: true },
        { coords: [67.83, 29.4], active: true },
        { coords: [71.4, 29.4], active: true },
        { coords: [78.54, 29.4] },
        { coords: [82.11, 29.4], active: true },
        { coords: [85.68, 29.4] },
        { coords: [89.25, 29.4] },
        { coords: [0, 35.28], active: true },
        { coords: [3.57, 35.28] },
        { coords: [7.14, 35.28], active: true },
        { coords: [10.71, 35.28] },
        { coords: [14.28, 35.28] },
        { coords: [17.85, 35.28] },
        { coords: [21.42, 35.28], active: true },
        { coords: [24.99, 35.28] },
        { coords: [28.56, 35.28] },
        { coords: [32.13, 35.28], active: true },
        { coords: [35.7, 35.28] },
        { coords: [39.27, 35.28] },
        { coords: [42.84, 35.28] },
        { coords: [46.41, 35.28], active: true },
        { coords: [49.98, 35.28], active: true },
        { coords: [53.55, 35.28] },
        { coords: [57.12, 35.28] },
        { coords: [60.69, 35.28] },
        { coords: [64.26, 35.28] },
        { coords: [67.83, 35.28] },
        { coords: [71.4, 35.28] },
        { coords: [74.97, 35.28], active: true },
        { coords: [78.54, 35.28], active: true },
        { coords: [82.11, 35.28] },
        { coords: [85.68, 35.28], active: true },
        { coords: [89.25, 35.28], active: true },
        { coords: [0, 41.16] },
        { coords: [3.57, 41.16] },
        { coords: [7.14, 41.16] },
        { coords: [10.71, 41.16] },
        { coords: [14.28, 41.16] },
        { coords: [17.85, 41.16] },
        { coords: [21.42, 41.16] },
        { coords: [24.99, 41.16] },
        { coords: [28.56, 41.16] },
        { coords: [32.13, 41.16], active: true },
        { coords: [35.7, 41.16] },
        { coords: [39.27, 41.16] },
        { coords: [42.84, 41.16] },
        { coords: [46.41, 41.16] },
        { coords: [49.98, 41.16] },
        { coords: [53.55, 41.16] },
        { coords: [57.12, 41.16] },
        { coords: [60.69, 41.16], active: true },
        { coords: [64.26, 41.16] },
        { coords: [67.83, 41.16], active: true },
        { coords: [71.4, 41.16] },
        { coords: [74.97, 41.16] },
        { coords: [78.54, 41.16] },
        { coords: [82.11, 41.16] },
        { coords: [85.68, 41.16], active: true },
        { coords: [0, 47.04], active: true },
        { coords: [3.57, 47.04] },
        { coords: [7.14, 47.04], active: true },
        { coords: [14.28, 47.04] },
        { coords: [17.85, 47.04] },
        { coords: [21.42, 47.04] },
        { coords: [24.99, 47.04], active: true },
        { coords: [28.56, 47.04] },
        { coords: [32.13, 47.04] },
        { coords: [35.7, 47.04] },
        { coords: [39.27, 47.04], active: true },
        { coords: [42.84, 47.04] },
        { coords: [46.41, 47.04] },
        { coords: [49.98, 47.04], active: true },
        { coords: [53.55, 47.04] },
        { coords: [57.12, 47.04], active: true },
        { coords: [60.69, 47.04] },
        { coords: [64.26, 47.04] },
        { coords: [67.83, 47.04] },
        { coords: [71.4, 47.04] },
        { coords: [74.97, 47.04], active: true },
        { coords: [78.54, 47.04] },
        { coords: [82.11, 47.04], active: true },
        { coords: [85.68, 47.04] },
        { coords: [3.57, 52.92] },
        { coords: [7.14, 52.92] },
        { coords: [10.71, 52.92], active: true },
        { coords: [14.28, 52.92] },
        { coords: [17.85, 52.92] },
        { coords: [21.42, 52.92] },
        { coords: [24.99, 52.92] },
        { coords: [28.56, 52.92] },
        { coords: [32.13, 52.92] },
        { coords: [35.7, 52.92] },
        { coords: [39.27, 52.92] },
        { coords: [42.84, 52.92] },
        { coords: [46.41, 52.92], active: true },
        { coords: [10.71, 47.04] },
        { coords: [49.98, 52.92] },
        { coords: [53.55, 52.92] },
        { coords: [57.12, 52.92] },
        { coords: [60.69, 52.92], active: true },
        { coords: [64.26, 52.92] },
        { coords: [67.83, 52.92], active: true },
        { coords: [71.4, 52.92], active: true },
        { coords: [74.97, 52.92] },
        { coords: [78.54, 52.92] },
        { coords: [82.11, 52.92], active: true },
        { coords: [85.68, 52.92] },
        { coords: [7.14, 58.8], active: true },
        { coords: [10.71, 58.8] },
        { coords: [14.28, 58.8] },
        { coords: [17.85, 58.8], active: true },
        { coords: [21.42, 58.8] },
        { coords: [24.99, 58.8] },
        { coords: [28.56, 58.8], active: true },
        { coords: [32.13, 58.8] },
        { coords: [35.7, 58.8] },
        { coords: [39.27, 58.8] },
        { coords: [42.84, 58.8] },
        { coords: [46.41, 58.8] },
        { coords: [49.98, 58.8], active: true },
        { coords: [53.55, 58.8] },
        { coords: [57.12, 58.8] },
        { coords: [60.69, 58.8] },
        { coords: [64.26, 58.8] },
        { coords: [67.83, 58.8] },
        { coords: [71.4, 58.8] },
        { coords: [74.97, 58.8] },
        { coords: [78.54, 58.8], active: true },
        { coords: [82.11, 58.8] },
        { coords: [10.71, 64.68] },
        { coords: [14.28, 64.68] },
        { coords: [17.85, 64.68] },
        { coords: [21.42, 64.68] },
        { coords: [24.99, 64.68] },
        { coords: [28.56, 64.68] },
        { coords: [32.13, 64.68] },
        { coords: [35.7, 64.68] },
        { coords: [39.27, 64.68] },
        { coords: [42.84, 64.68] },
        { coords: [46.41, 64.68] },
        { coords: [49.98, 64.68] },
        { coords: [53.55, 64.68] },
        { coords: [57.12, 64.68], active: true },
        { coords: [60.69, 64.68] },
        { coords: [64.26, 64.68] },
        { coords: [67.83, 64.68] },
        { coords: [71.4, 64.68], active: true },
        { coords: [74.97, 64.68] },
        { coords: [78.54, 64.68] },
        { coords: [82.11, 64.68], active: true },
        { coords: [17.85, 70.56] },
        { coords: [21.42, 70.56] },
        { coords: [24.99, 70.56] },
        { coords: [28.56, 70.56], active: true },
        { coords: [32.13, 70.56] },
        { coords: [35.7, 70.56], active: true },
        { coords: [39.27, 70.56] },
        { coords: [42.84, 70.56] },
        { coords: [46.41, 70.56], active: true },
        { coords: [49.98, 70.56], active: true },
        { coords: [53.55, 70.56] },
        { coords: [57.12, 70.56] },
        { coords: [60.69, 70.56], active: true },
        { coords: [64.26, 70.56] },
        { coords: [67.83, 70.56], active: true },
        { coords: [71.4, 70.56] },
        { coords: [74.97, 70.56] },
        { coords: [78.54, 70.56] },
        { coords: [28.56, 76.44] },
        { coords: [32.13, 76.44], active: true },
        { coords: [35.7, 76.44], active: true },
        { coords: [39.27, 76.44] },
        { coords: [42.84, 76.44] },
        { coords: [46.41, 76.44] },
        { coords: [49.98, 76.44] },
        { coords: [53.55, 76.44] },
        { coords: [57.12, 76.44], active: true },
        { coords: [60.69, 76.44] },
        { coords: [64.26, 76.44], active: true },
        { coords: [67.83, 76.44], active: true },
        { coords: [71.4, 76.44] },
        { coords: [74.97, 76.44] },
        { coords: [78.54, 76.44], active: true },
        { coords: [32.13, 82.32] },
        { coords: [35.7, 82.32] },
        { coords: [39.27, 82.32] },
        { coords: [42.84, 82.32], active: true },
        { coords: [46.41, 82.32] },
        { coords: [49.98, 82.32], active: true },
        { coords: [53.55, 82.32] },
        { coords: [57.12, 82.32] },
        { coords: [60.69, 82.32], active: true },
        { coords: [74.97, 82.32] },
        { coords: [78.54, 82.32] },
        { coords: [39.27, 88.2], active: true },
        { coords: [42.84, 88.2] },
        { coords: [46.41, 88.2] },
        { coords: [78.54, 88.2] },
        { coords: [82.11, 88.2], active: true },
        { coords: [42.84, 94.08] },
        { coords: [82.11, 94.08], active: true }
      ]
    };
  },
  methods: {
    setScale(val) {
      this.wrapperStyle.transform = `scale(${val})`;
    },
    randomDelay() {
      return Math.random() * 3;
    },
    watchResize() {
      let self = this;
      const parent = this.$el;
      const el = this.$refs.wrapper;
      const resizeObserver = new ResizeObserver(entries => {
        const scale = Math.min(
          parent.offsetWidth / el.offsetWidth,
          parent.offsetHeight / el.offsetHeight
        );
        self.setScale(scale);
      });
      resizeObserver.observe(parent);
    }
  },
  mounted() {
    this.watchResize();
  }
};
</script>

<style lang="scss" scoped>
$orange: #fdbe19;
$dark-orange: #ed560e;
$blue: #8fb5cc;
$dark-blue: #003e6a;

@keyframes grow {
  100% {
    transform: scale(4);
    opacity: 0;
  }
}

.transition-fast {
  transition: all 0.3s ease;
}

.point {
  width: 6px;
  height: 6px;
}

.active {
  z-index: 1;
  height: 12px !important;
  width: 12px !important;
  transition: 0.2s background-color ease-in-out;
  background: linear-gradient(135deg, $orange 0%, $dark-orange 80%);

  &::after {
    content: "";
    border-radius: 50%;
    position: absolute;
    display: block;
    background: linear-gradient(135deg, $blue 0%, $dark-blue 80%);
    opacity: 0;
    height: 100%;
    width: 100%;
    @extend .transition-fast;
  }
}

.active-glow {
  background-color: $dark-orange;
  margin: 2px;
  border-radius: 50%;
  z-index: -1;
  height: 8px;
  width: 8px;
  opacity: 0.2;
  box-shadow: 0 0 0 2px $dark-orange;
  animation: grow 3s ease-in-out infinite;
}

.wrapper:hover {
  .active::after {
    opacity: 1;
  }

  .active-glow {
    background-color: $blue;
    box-shadow: 0 0 0 2px $blue;
  }
}
</style>
