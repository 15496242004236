<template>
  <div class="overflow-hidden">
    <HeroV2 :visible.sync="visible" class="pt-28">
      <XrayWrapperCommercial />
    </HeroV2>

    <main>
      <b-container class="mt-16 mt-lg-24">
        <b-row>
          <b-col lg="12">
            <div class="mb-8 mb-md-12 mb-lg-0">
              <h2
                class="
                  font-weight-black
                  text-center
                  tracking-tight
                  text-3xl text-md-5xl text-xl-6xl
                  font-realist
                  leading-none
                  mb-0
                  text-blue-500
                "
              >
                We're Your Fleet's <br class="d-none d-lg-inline" />Accurate
                <span class="stylistic-alt">&amp;</span> Unbiased
                <br class="d-none d-lg-inline" />
                Inspection Partner
              </h2>
            </div>
          </b-col>
          <b-col
            lg="10"
            class="d-flex mx-auto mt-xl-10 mt-3 align-items-center"
          >
            <div>
              <p class="text-md-3xl text-sm-xl text-lg text-center mb-0">
                True360 provides comprehensive solutions for your inspection and
                consultative needs, for the top automotive companies in the
                world, and for individuals&nbsp;nearing&nbsp;lease&nbsp;end.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="mt-18 mt-sm-32 mt-xl-56">
        <b-row>
          <b-col lg="6" class="align-items-end">
            <div class="mb-0 mb-md-12 mb-lg-0">
              <ReachMap />
            </div>
          </b-col>
          <b-col lg="6" class="d-flex align-items-center">
            <div>
              <div class="mb-8">
                <h2
                  class="
                    font-weight-black
                    text-center text-lg-left
                    tracking-tight
                    text-3xl text-md-5xl text-xl-6xl
                    font-realist
                    leading-none
                    mb-0
                    text-blue-500
                  "
                >
                  Improve Your <br class="d-none d-lg-inline" />Bottom Line
                  <br class="d-sm-inline d-none" /><span class="stylistic-alt"
                    >&amp;</span
                  >
                  Gain Efficiency
                </h2>
              </div>
              <p class="text-md text-sm-lg text-md-xl mb-0">
                The most efficient and effective way to inspect and assess
                pre-owned vehicles and dealership services. Spend less time
                managing your remarketing programs. Reduce the time vehicles
                spend on your lot.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="mt-18 mt-md-32 mt-xl-56">
        <b-row>
          <b-col lg="6" order-lg="2" align-self="center">
            <div class="mb-8 mb-md-12 mb-lg-0">
              <img
                src="@/assets/img/dealer-fleet.png"
                class="section-asset"
                width="738"
              />
            </div>
          </b-col>
          <b-col lg="6" class="d-flex align-items-center" order-lg="1">
            <div>
              <div class="mb-8">
                <h2
                  class="
                    font-weight-black
                    text-center text-lg-left
                    tracking-tight
                    text-3xl text-md-5xl text-xl-6xl
                    font-realist
                    leading-none
                    mb-0
                    text-blue-500
                  "
                >
                  Tailored Solutions
                </h2>
              </div>
              <div class="mb-4 d-flex align-items-start px-5 px-sm-18 px-lg-0">
                <inline-svg
                  class="icon"
                  :src="require(`@/assets/svg/commercial_01_metrics.svg`)"
                />

                <div class="icon-content">
                  <h3
                    class="
                      font-weight-bold
                      tracking-tight
                      font-realist
                      leading-tight
                      text-md-4xl text-xl
                      mb-1 mb-sm-3
                      text-blue-500
                      pt-2 pt-sm-0
                    "
                  >
                    Effortlessly Manage <br class="d-none d-md-inline" />Your
                    Dealership’s Metrics
                  </h3>
                  <p class="text-md text-sm-lg text-md-xl mb-0">
                    Our CPO and Off-Lease Vehicle Condition Inspectors and
                    Consultants are trained according to the specific guidelines
                    established by each partner.
                  </p>
                </div>
              </div>
              <div class="mb-4 d-flex px-5 px-sm-18 px-lg-0 align-items-start">
                <inline-svg
                  class="icon"
                  :src="require(`@/assets/svg/commercial_02_support.svg`)"
                />
                <div class="icon-content">
                  <h3
                    class="
                      font-weight-bold
                      tracking-tight
                      font-realist
                      leading-tight
                      text-md-4xl text-xl
                      mb-3
                      text-blue-500
                      pt-1 pt-sm-0
                    "
                  >
                    Industry Leading Customer
                    <br class="d-none d-md-inline" />Service &amp; Support
                  </h3>
                  <ul class="text-md text-sm-lg text-md-xl mb-0">
                    <li>Dedicated Account Manager</li>
                    <li>Centralized Customer Care Center</li>
                    <li>Robust training programs for your employees</li>
                    <li>Multi lingual programs available</li>
                    <li>Integrated, customizable reports and dashboards</li>
                  </ul>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="mt-18 mt-md-32 mt-xl-56">
        <b-row>
          <b-col lg="6" order-lg="1">
            <div class="mb-8 mb-md-12 mb-lg-0 px-5 px-sm-18 px-lg-0 mr-n10">
              <img
                src="@/assets/img/commercial_preowned.jpg"
                class="section-asset w-100"
              />
            </div>
          </b-col>
          <b-col lg="6" class="d-flex align-items-center" order-lg="2">
            <div>
              <div class="mb-8">
                <h2
                  class="
                    font-weight-black
                    text-center text-xl-left
                    tracking-tight
                    text-3xl text-md-5xl text-xl-6xl
                    font-realist
                    leading-none
                    mb-0
                    text-blue-500
                  "
                >
                  Certified Pre-Owned Services
                </h2>
              </div>
              <div class="mb-4 d-flex align-items-start px-5 px-sm-18 px-lg-0">
                <inline-svg
                  class="icon"
                  :src="require(`@/assets/svg/commercial_03_brand.svg`)"
                />
                <div class="icon-content">
                  <h3
                    class="
                      font-weight-bold
                      tracking-tight
                      font-realist
                      leading-tight
                      text-md-4xl text-xl
                      mb-3
                      text-blue-500
                      pt-3 pt-sm-0
                    "
                  >
                    Protecting Your Brand
                  </h3>
                  <p class="text-md text-sm-lg text-md-xl mb-0">
                    Ensure each vehicle represented as CPO is a further
                    demonstration of your brand’s integrity and standards.
                  </p>
                </div>
              </div>
              <div class="mb-4 d-flex align-items-start px-5 px-sm-18 px-lg-0">
                <inline-svg
                  class="icon"
                  :src="require(`@/assets/svg/commercial_04_capabilities.svg`)"
                />
                <div class="icon-content">
                  <h3
                    class="
                      font-weight-bold
                      tracking-tight
                      font-realist
                      leading-tight
                      text-md-4xl text-xl
                      mb-3
                      text-blue-500
                      pt-2 pt-sm-0
                    "
                  >
                    Enhanced Capabilities
                  </h3>
                  <p class="text-md text-sm-lg text-md-xl mb-0">
                    Our consultants review adherence to CPO standards including
                    document compliance, point of sale, and branding
                    requirements.
                  </p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="mt-18 mt-md-32 mt-xl-56">
        <b-row>
          <b-col lg="6" order-lg="2" class="d-flex align-items-center">
            <div class="mb-8 mb-md-12 mb-lg-0">
              <img
                src="@/assets/img/commercial_offlease.jpg"
                class="section-asset"
              />
            </div>
          </b-col>
          <b-col lg="6" class="d-flex align-items-center" order-lg="1">
            <div>
              <div class="mb-8">
                <h2
                  class="
                    font-weight-black
                    text-center text-lg-left
                    tracking-tight
                    text-3xl text-md-5xl text-xl-6xl
                    font-realist
                    leading-none
                    mb-0
                    text-blue-500
                  "
                >
                  Off-Lease Services
                </h2>
              </div>
              <div class="mb-4 d-flex align-items-start px-5 px-sm-18 px-lg-0">
                <inline-svg
                  class="icon"
                  :src="require(`@/assets/svg/commercial_05_precision.svg`)"
                />
                <div class="icon-content">
                  <h3
                    class="
                      font-weight-bold
                      tracking-tight
                      font-realist
                      leading-tight
                      text-md-4xl text-xl
                      mb-3
                      text-blue-500
                      pt-2 pt-sm-0
                    "
                  >
                    Precision Analysis
                  </h3>
                  <p class="text-md text-sm-lg text-md-xl mb-0">
                    Our team has been trained to recognize and estimate normal
                    and excessive damage, as well as identify previous repair
                    and structural damage, according to the standards set by
                    each partner.
                  </p>
                </div>
              </div>
              <div class="mb-4 d-flex align-items-start px-5 px-sm-18 px-lg-0">
                <inline-svg
                  class="icon"
                  style="margin-left: -20px"
                  :src="require(`@/assets/svg/commercial_06_condition.svg`)"
                />
                <div class="icon-content">
                  <h3
                    class="
                      font-weight-bold
                      tracking-tight
                      font-realist
                      leading-tight
                      text-md-4xl text-xl
                      mb-3
                      text-blue-500
                      pt-4 pt-sm-0
                    "
                  >
                    Quickly Verify Condition
                  </h3>
                  <p class="text-md text-sm-lg text-md-xl mb-0">
                    True360 inspections provide a thorough and independent view
                    of a vehicle’s condition, including cost analysis for any
                    recorded damage.
                  </p>
                </div>
              </div>
              <div class="mb-4 d-flex align-items-start px-5 px-sm-18 px-lg-0">
                <inline-svg
                  class="icon"
                  :src="require(`@/assets/svg/commercial_06_reports.svg`)"
                />
                <div class="icon-content">
                  <h3
                    class="
                      font-weight-bold
                      tracking-tight
                      font-realist
                      leading-tight
                      text-md-4xl text-xl
                      mb-3
                      text-blue-500
                      pt-5 pt-sm-0
                    "
                  >
                    Detailed Inspection Reports
                  </h3>
                  <p class="text-md text-sm-lg text-md-xl mb-0">
                    Our customized, independent inspection reports allow your
                    customers to see a detailed analysis of the condition to
                    help them make informed decisions.
                  </p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="mt-18 mt-md-32 mt-xl-56 mb-10 mb-lg-56">
        <b-row>
          <b-col
            lg="8"
            offset-lg="2"
            offset-xl="0"
            xl="6"
            order-xl="0"
            order="1"
            class="d-flex align-items-center position-relative"
          >
            <div class="mb-12 mb-xl-0">
              <img
                class="w-100"
                src="@/assets/img/liquidate_wholesale_fast.png"
              />
            </div>
          </b-col>
          <b-col
            xl="6"
            order-xl="1"
            order="0"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-xl-start
              mb-18 mb-xl-0
            "
          >
            <div>
              <div class="mb-8">
                <h2
                  class="
                    font-weight-black
                    text-center text-xl-left
                    tracking-tight
                    text-3xl text-md-5xl text-xl-6xl
                    font-realist
                    leading-none
                    mb-0
                    text-blue-500
                  "
                >
                  Seamless <br class="d-lg-inline" />Liquidation
                  <br class="d-lg-inline" />Options
                </h2>
              </div>
              <p class="text-xl text-center text-lg-left text-md-2xl mb-0 pr-4">
                Our integrations allow you to seamlessly launch your vehicles to
                the nation's largest dealer to dealer online marketplace
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </main>
  </div>
</template>

<script>
import ReachMap from "@/components/ReachMap";
import HeroV2 from "@/components/global/HeroV2";
import XrayWrapperCommercial from "@/components/XrayWrapperCommercial";
import InlineSvg from "vue-inline-svg";

export default {
  name: "CommercialPage",
  components: {
    HeroV2,
    ReachMap,
    InlineSvg,
    XrayWrapperCommercial
  },
  watch: {
    visible() {
      this.$emit("update:heroIsVisible", this.visible);
    }
  },
  data() {
    return {
      visible: true,
      fleetIsVisible: false
    };
  }
};
</script>
<style lang="scss" scoped>
.hero-container {
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(14, 103, 156, 1) 0%,
    rgba(92, 173, 218, 1) 50%,
    rgba(204, 220, 229, 1) 100%
  );

  height: 300px;
  @media (min-width: 415px) {
    height: 450px;
  }
  @media (min-width: 767px) {
    height: 500px;
  }
  @media (min-width: 992px) {
    height: 650px;
  }
  @media (min-width: 1367px) {
    height: 700px;
  }
  @media (min-width: 1719px) {
    height: calc(1382 / 2000 * 1000vw);
    max-height: 800px;
  }
}
.background {
  height: 100%;
  width: 100%;
  background-image: url("../assets/img/hero-backdrop.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
}

.art-container {
  position: absolute;
  bottom: -100px;
}
.text-container {
  margin: 6vw auto 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  padding: 0 5vw;
  text-align: center;

  @media (max-width: 767px) {
    padding: 0 5vw;
  }

  @media (min-width: 1200px) {
    margin-top: 80px;
  }
  @media (min-width: 1719px) {
    margin-top: 100px;
  }

  h1 {
    text-shadow: 0.25vw 0.25vw 1vw rgba(#004e7d, 0.13);
  }

  span {
    display: block;
    font-size: 1.75vw;
    line-height: 1.3;
    margin: auto;
    font-weight: 400;
    @media (max-width: 767px) {
      font-size: 13px;
      max-width: 100%;
    }
    max-width: 70%;
  }
}

.section-asset {
  @media (max-width: 991px) {
    width: 100%;
  }
}

.icon {
  // width: 100px;
  // max-width: 100px;
  height: auto;
  max-height: initial;
}

.icon-content {
  max-width: 90%;
  padding-left: 1rem;
  margin-bottom: 1rem;

  p {
    padding-right: 1rem;
  }
  ul {
    padding-left: 1.5rem;
  }
}
</style>
